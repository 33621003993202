import React from 'react'
import { PlayerAPI, PlayerEventBase } from 'bitmovin-player'
import { UIInstanceManager } from 'bitmovin-player-ui'
import { EventDispatcher } from 'bitmovin-player-ui/dist/js/framework/eventdispatcher'
import { AudioTrack } from 'bitmovin-player/types/core/PlayerAPI'
import { SubtitleTrack } from 'bitmovin-player/types/subtitles/API'
import { NextRouter } from 'next/router'
import { AngelPlayerData } from '@/organisms/AngelPlayer/types/AngelPlayerTypes'
import { MomentTypes } from '@/types/codegen-federation'

export interface BitmovinUILoaderProps {
  markers?: TimelineMarker[]
  disableCommunityView?: boolean
  hideCommunityViewToggleButton?: boolean
  player?: PlayerAPI
}

export interface BitmovinPlayerProps {
  aspectClassName?: string
  asset: Asset
  autoplay?: boolean
  enableAds?: boolean
  onViewModeChanged?: (event: ViewModeChangedEvent) => void
  onAdStarted?: () => void
  onAdFinished?: () => void
  onAdSkipped?: () => void
  markers?: TimelineMarker[]
  overlays?: PlayerOverlay[]
  onPlayerInit?: (player: PlayerAPI) => void
  anonymousUserId?: string
  loggedInUserId?: string | null | undefined
  isStrict?: boolean
  disableCommunityView?: boolean
  hideCommunityViewToggleButton?: boolean
}

export type TimelineMarkerType = 'heart' | string

export interface TimelineMarker {
  time: number
  duration?: number
  title?: string
  imageUrl?: string
  cssClasses?: string[]
  markerType?: TimelineMarkerType
  sceneType?: MomentTypes
}

interface NoArgs {}
export interface CommunityViewChagedArgs extends NoArgs {
  isOn: boolean
  caller?: string
}

export interface AngelUIInstanceManager extends UIInstanceManager {
  onCommunityViewToggled: EventDispatcher<UIInstanceManager, CommunityViewChagedArgs>
}

export interface UIManager {
  addTimelineMarker: (timelineMarker: TimelineMarker) => void
  removeTimelineMarker: (timelineMarker: TimelineMarker) => void
  release: () => void
  showTimelineMarkers: () => void
  hideTimelineMarkers: () => void
  markScene: (timelineMarker: TimelineMarker) => void
  uiInstanceManagers: AngelUIInstanceManager[]
  currentUi: AngelUIInstanceManager
}

export interface BitmovinPlayerClassProps extends BitmovinPlayerProps, AngelPlayerData {
  router: NextRouter
}

export type VideoType = 'episode' | 'video' | 'story' | 'livestream' | 'pif-explainer' | 'torch'

export interface SourceMetadata {
  upNext?: UpNextConfig
  nifty?: BitmovinNft
  moments?: BitmovinMoments
  videoType?: VideoType
}

export interface UpNextConfig {
  /**
   * Required. If this value is missing, the up next functionality will be disabled.
   * Callback used when the user clicks the button or when the automatic go to next is called. Should include a call of next/router.push(nextVideoUrl).
   */
  goToNext?: () => void

  /**
   * Required. If this value is missing, the up next functionality will be disabled.
   * In seconds, should be set to appear before the end of the video. Generally 8 seconds after the credits start.
   */
  showTime?: number

  /**
   * Optional.
   * Automatically call the goToNext function after this many milliseconds if the mouse has not moved over the player.
   * This value must be greater than 2000, otherwise the experience will be buggy.
   * The user needs to be given time to react, so a minimum of 10000 is recommended if this feature is used.
   */
  autoGoToNextAfterMs?: number
}

export interface BitmovinNft {
  nftEnabled: boolean
  moments?: BitmovinMoment[]
}

export interface BitmovinMoments {
  enabled: boolean
}

/**
 * HH:MM:SS:FF
 * Frame should be a number between 00 and 23 for 24fps videos
 */
export type SmpteTimestamp = string

export interface AssetAnalytics {
  airdate?: string
  content_id: string
  description: string
  episodeNumber?: number
  seasonId?: string
  title: string
  total_length?: number
  position: number
  videoType: 'episode' | 'video' | 'story' | 'livestream' | 'pif-explainer' | 'torch'
}

export interface Asset {
  id?: number
  projectSlug?: string
  projectName?: string
  publisherName?: string
  name: string
  position?: number | SmpteTimestamp
  frameRate: number
  framesDroppedEachMinute: number
  adjustmentFactor: number
  vmapUrl?: string
  sourceConfig: {
    guid: string
    slug: string
    hls: string
    poster?: string
    title: string
    subtitle?: string
    description?: string
    credits?: number
    metadata?: SourceMetadata
  }
  analytics: AssetAnalytics
}

export interface BitmovinMoment {
  startFrameNumber: number
  endFrameNumber: number
  title: string
}

export enum PlayerEvent {
  Paused = 'paused',
  Playing = 'playing',
  Play = 'play',
  Seek = 'seek',
  Seeked = 'seeked',
  SourceLoaded = 'sourceloaded',
  TimeChanged = 'timechanged',
  PlaybackFinished = 'playbackfinished',
  AudioChanged = 'audiochanged',
  SubtitleEnabled = 'subtitleenabled',
  SubtitleDisabled = 'subtitledisabled',
  Ready = 'ready',
  ViewModeChanged = 'viewmodechanged',
  AdStarted = 'adstarted',
  AdFinished = 'adfinished',
  AdSkipped = 'adskipped',
}

export interface PlayerOverlay {
  key: string
  Component: React.FC
}

export interface ViewModeChangedEvent extends PlayerEventBase {
  from: ViewMode
  to: ViewMode
  legacy: boolean
}

export interface SmpteController {
  load: (asset: AssetDescription) => void
  seekToSMPTE: (smpteString: string) => void
  getCurrentSmpte: () => string | undefined
  getCurrentTime: () => number
  step: (stepSize: number) => void
  play: () => void
  pause: () => void
  seek: (seekTo: number) => void
  exitFullscreen: () => void
  enterFullscreen: () => void
  isPlaying: () => boolean
  destroy: () => void
  getAvailableAudio: () => AudioTrack[]
  getSubtitleList: () => SubtitleTrack[]
  setAudio: (audioId: string) => void
  enableSubTitle: (subtitleTrackId: string) => void
}

export enum ViewMode {
  Inline = 'inline',
  Fullscreen = 'fullscreen',
  PictureInPicture = 'pictureinpicture',
}

export interface AssetDescription {
  name: string
  sourceConfig: {
    [keys: string]: unknown
  }
  framesPerSecond: number
  adjustmentFactor: number
  framesDroppedAtFullMinute: number
  frameDuration: number
  offsetToMidFrame: number
  position?: number | SmpteTimestamp
}

export enum AdTagType {
  VMAP = 'vmap',
  VAST = 'vast',
}
