import React from 'react'
import { getHexColor, OneColorIconProps } from '@/atoms/utils'

export const RewindIcon: React.FC<OneColorIconProps> = ({ size = 40, className, color = 'white' }) => {
  const c = getHexColor(color)
  return (
    <svg
      width={size}
      height={size}
      fill="none"
      color={c}
      viewBox="0 0 35 40"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="rewind" fill="currentColor" fillRule="nonzero">
          <path
            d="M0,22.657 C0,21.794 0.7,21.094 1.563,21.094 C2.426,21.094 3.125,21.794 3.125,22.657 C3.125,30.422 9.422,36.719 17.188,36.719 C24.953,36.719 31.25,30.422 31.25,22.657 C31.25,14.891 24.953,8.594 17.187,8.594 L13.281,8.594 C12.418,8.594 11.719,7.895 11.719,7.032 C11.719,6.169 12.418,5.469 13.281,5.469 L17.187,5.469 C26.679,5.469 34.375,13.165 34.375,22.657 C34.375,32.149 26.68,39.844 17.187,39.844 C7.695,39.844 0,32.15 0,22.657 Z"
            id="Path"
          />
          <path
            d="M16.03,11.483 C16.592,12.139 16.516,13.125 15.862,13.686 C15.205,14.248 14.219,14.172 13.658,13.518 L8.971,8.049 C8.469,7.463 8.469,6.599 8.971,6.014 L13.658,0.544 C14.219,-0.109 15.205,-0.186 15.862,0.377 C16.516,0.937 16.592,1.924 16.029,2.58 L12.215,7.031 L16.029,11.483 L16.03,11.483 Z"
            id="Path"
          />
          <path
            d="M8.217,11.483 C8.78,12.139 8.703,13.125 8.049,13.686 C7.393,14.248 6.406,14.172 5.846,13.518 L1.158,8.049 C0.656,7.463 0.656,6.599 1.158,6.014 L5.846,0.544 C6.406,-0.109 7.393,-0.186 8.049,0.377 C8.703,0.937 8.779,1.924 8.217,2.58 L4.403,7.031 L8.217,11.483 L8.217,11.483 Z"
            id="Path"
          />
        </g>
      </g>
    </svg>
  )
}
