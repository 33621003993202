import React from 'react'
import { getHexColor, OneColorIconProps } from '../utils'

export const FullscreenIcon: React.FC<OneColorIconProps> = ({ className, color = 'core-gray-100', size = 20 }) => {
  const hex = getHexColor(color)

  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M4 21C3.71667 21 3.47917 20.9042 3.2875 20.7125C3.09583 20.5208 3 20.2833 3 20V16C3 15.7167 3.09583 15.4792 3.2875 15.2875C3.47917 15.0958 3.71667 15 4 15C4.28333 15 4.52083 15.0958 4.7125 15.2875C4.90417 15.4792 5 15.7167 5 16V19H9C9.28333 19 9.52083 19.0958 9.7125 19.2875C9.90417 19.4792 10 19.7167 10 20C10 20.2833 9.90417 20.5208 9.7125 20.7125C9.52083 20.9042 9.28333 21 9 21H4ZM4 9C3.71667 9 3.47917 8.90417 3.2875 8.7125C3.09583 8.52083 3 8.28333 3 8V4C3 3.71667 3.09583 3.47917 3.2875 3.2875C3.47917 3.09583 3.71667 3 4 3H9C9.28333 3 9.52083 3.09583 9.7125 3.2875C9.90417 3.47917 10 3.71667 10 4C10 4.28333 9.90417 4.52083 9.7125 4.7125C9.52083 4.90417 9.28333 5 9 5H5V8C5 8.28333 4.90417 8.52083 4.7125 8.7125C4.52083 8.90417 4.28333 9 4 9ZM15 21C14.7167 21 14.4792 20.9042 14.2875 20.7125C14.0958 20.5208 14 20.2833 14 20C14 19.7167 14.0958 19.4792 14.2875 19.2875C14.4792 19.0958 14.7167 19 15 19H19V16C19 15.7167 19.0958 15.4792 19.2875 15.2875C19.4792 15.0958 19.7167 15 20 15C20.2833 15 20.5208 15.0958 20.7125 15.2875C20.9042 15.4792 21 15.7167 21 16V20C21 20.2833 20.9042 20.5208 20.7125 20.7125C20.5208 20.9042 20.2833 21 20 21H15ZM20 9C19.7167 9 19.4792 8.90417 19.2875 8.7125C19.0958 8.52083 19 8.28333 19 8V5H15C14.7167 5 14.4792 4.90417 14.2875 4.7125C14.0958 4.52083 14 4.28333 14 4C14 3.71667 14.0958 3.47917 14.2875 3.2875C14.4792 3.09583 14.7167 3 15 3H20C20.2833 3 20.5208 3.09583 20.7125 3.2875C20.9042 3.47917 21 3.71667 21 4V8C21 8.28333 20.9042 8.52083 20.7125 8.7125C20.5208 8.90417 20.2833 9 20 9Z"
        fill={hex}
      />
    </svg>
  )
}
