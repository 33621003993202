import React from 'react'
import { getHexColor, OneColorIconProps } from '../utils'

export const PauseIcon: React.FC<OneColorIconProps> = ({ className, color = 'core-gray-100', size = 20 }) => {
  const hex = getHexColor(color)

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M14.1228 23.6666C13.8012 23.6666 13.5164 23.542 13.2684 23.2929C13.0193 23.0449 12.8947 22.7602 12.8947 22.4385V1.56132C12.8947 1.23968 13.0193 0.954304 13.2684 0.705182C13.5164 0.457228 13.8012 0.333252 14.1228 0.333252H18.7719C19.0936 0.333252 19.3789 0.457228 19.6281 0.705182C19.876 0.954304 20 1.23968 20 1.56132V22.4385C20 22.7602 19.876 23.0449 19.6281 23.2929C19.3789 23.542 19.0936 23.6666 18.7719 23.6666H14.1228ZM1.22807 23.6666C0.906432 23.6666 0.621637 23.542 0.373684 23.2929C0.124561 23.0449 0 22.7602 0 22.4385V1.56132C0 1.23968 0.124561 0.954304 0.373684 0.705182C0.621637 0.457228 0.906432 0.333252 1.22807 0.333252H5.87719C6.19883 0.333252 6.48421 0.457228 6.73333 0.705182C6.98128 0.954304 7.10526 1.23968 7.10526 1.56132V22.4385C7.10526 22.7602 6.98128 23.0449 6.73333 23.2929C6.48421 23.542 6.19883 23.6666 5.87719 23.6666H1.22807Z"
        fill={hex}
      />
    </svg>
  )
}
