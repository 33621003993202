import React, { useMemo } from 'react'
import classNames from 'classnames'
import { ReactFCC } from '@/types/react'

interface GlassCircleProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  size: string | number
}

export const GlassCircle: ReactFCC<GlassCircleProps> = ({ size, children, ...props }) => {
  const style = useMemo(() => {
    return { height: size, width: size }
  }, [size])

  return (
    <div
      className={classNames('relative ml-auto rounded-full bg-white/20 backdrop-blur-md', {
        'cursor-pointer hover:bg-white/30': props.onClick,
      })}
      style={style}
      {...props}
    >
      <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">{children}</div>
    </div>
  )
}
