import React, { MouseEventHandler, useCallback, useEffect, useState, useRef } from 'react'
import classNames from 'classnames'
import { Case, Default, Switch } from 'react-if'
import { GlassCircle } from '@/atoms/GlassCircle'
import { FullscreenIcon } from '@/atoms/Icons/FullscreenIcon'
import { PauseIcon } from '@/atoms/Icons/PauseIcon'
import { PlayIcon } from '@/atoms/Icons/PlayIcon'
import { RewindIcon } from '@/atoms/Icons/RewindIcon'
import { useBitAngelPlayer } from '@/organisms/BitAngelPlayer/BitAngelPlayerContext'

export const PifVideoPlayerUI: React.FC = () => {
  const player = useBitAngelPlayer()
  const [isShowControls, setShowControls] = useState<boolean>(true)
  const hideControlsTimeoutRef = useRef<NodeJS.Timeout | null>(null)

  const showControls = useCallback(() => {
    if (!isShowControls) {
      setShowControls(true)
    }
    const timeout = setTimeout(() => {
      if (player.isPlaying) {
        setShowControls(false)
      }
    }, 1500)
    if (hideControlsTimeoutRef.current) {
      clearTimeout(hideControlsTimeoutRef.current)
    }
    hideControlsTimeoutRef.current = timeout
  }, [isShowControls, player.isPlaying])

  useEffect(() => {
    return () => {
      if (hideControlsTimeoutRef.current) {
        clearTimeout(hideControlsTimeoutRef.current)
      }
    }
  }, [])

  const toggleFullscreen: MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation()
    player.toggleFullscreen()
  }

  return (
    <div className={classNames('absolute inset-3', { 'cursor-none': !isShowControls })} onMouseMove={showControls}>
      <div
        className={classNames('flex flex-col w-full h-full', {
          hidden: !isShowControls,
          'cursor-pointer': isShowControls,
        })}
        onClick={() => {
          if (!player.isPlaying) {
            setShowControls(false)
          }
          player.togglePlayback()
        }}
      >
        <div className="relative h-full w-full">
          <div className="absolute left-1/2 top-1/2 grow-1 -translate-x-1/2 -translate-y-1/2">
            <GlassCircle size={60}>
              <Switch>
                <Case condition={player.isPlaying}>
                  <PauseIcon size={30} />
                </Case>
                <Case condition={player.hasCompleted}>
                  <RewindIcon size={30} />
                </Case>
                <Default>
                  <PlayIcon size={30} />
                </Default>
              </Switch>
            </GlassCircle>
          </div>
        </div>
        {!player.hideFullscreen && (
          <div className="mt-auto flex shrink-0 grow-0 items-center">
            <GlassCircle size={40} onClick={toggleFullscreen}>
              <FullscreenIcon />
            </GlassCircle>
          </div>
        )}
      </div>
    </div>
  )
}
