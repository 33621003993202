import type { PlayerAPI } from 'bitmovin-player'
import isNumber from 'lodash/isNumber'

export interface BitAngelPlayerReducerState {
  hasStarted: boolean
  isPlaying: boolean
  isFullscreen: boolean
  hasCompleted: boolean
}

type BitAngelPlayerAction =
  | { type: 'toggleFullscreen' }
  | { type: 'play'; payload?: { seekTo?: number } }
  | { type: 'pause' }
  | { type: 'playbackFinished'; payload: { hasCompleted: boolean } }

const initialState: BitAngelPlayerReducerState = {
  hasStarted: false,
  isPlaying: false,
  isFullscreen: false,
  hasCompleted: false,
}

export const bitAngelPlayerReducer =
  (player?: PlayerAPI) =>
  (state: BitAngelPlayerReducerState = initialState, action: BitAngelPlayerAction): BitAngelPlayerReducerState => {
    switch (action.type) {
      case 'play':
        if (isNumber(action.payload?.seekTo)) {
          player?.seek(action.payload?.seekTo as number)
        }
        if (!state.isPlaying) {
          player?.play()
        }

        return {
          ...state,
          hasCompleted: false,
          isPlaying: true,
          hasStarted: true,
        }
      case 'pause':
        if (state.isPlaying) {
          player?.pause()
        }

        return {
          ...state,
          isPlaying: false,
        }
      case 'playbackFinished':
        return { ...state, isPlaying: false, hasCompleted: true, hasStarted: false }
      default:
        return state
    }
  }
