import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import Bugsnag from '@bugsnag/js'
import type { PlayerAPI } from 'bitmovin-player'
import { BitAngelPlayerProvider } from '@/organisms/BitAngelPlayer/BitAngelPlayerContext/BitAngelPlayerContext'
import { BitAngelPlayerTimeProvider } from '@/organisms/BitAngelPlayer/BitAngelPlayerContext/BitAngelPlayerTimeContext'
import { ReactFCC } from '@/types/react'

interface BitAngelPlayerProps {
  autoplay?: boolean
  className?: string
  hideFullscreen?: boolean
  customStyles?: string
  height?: string
  ui: React.FC
  url: string
  width?: string
  onStart: () => void
  onPlaybackFinished: () => void
  onPlayerInit?: (player: PlayerAPI) => void
}

export const BitAngelPlayer: ReactFCC<BitAngelPlayerProps> = ({
  autoplay,
  hideFullscreen,
  className,
  customStyles,
  height = '100%',
  ui,
  url,
  width = '100%',
  onStart,
  onPlaybackFinished,
  onPlayerInit,
  children,
}) => {
  const playerRef = useRef<HTMLDivElement>(null)
  const [player, setPlayer] = useState<PlayerAPI>()
  const containerRef = useRef<HTMLDivElement>(null)

  const playerConfig = useMemo(() => {
    return {
      key: process.env.NEXT_PUBLIC_BITMOVIN_PLAYER_KEY as string,
      ui: false,
      style: {
        width,
        height,
      },
    }
  }, [width, height])

  const setupPlayer = useCallback(
    async (url: string) => {
      try {
        const { Player } = await import('bitmovin-player')
        const playerInstance = new Player(playerRef.current as HTMLDivElement, playerConfig)
        const config = {
          hls: url.endsWith('.m3u8') ? url : undefined,
          progressive: url.endsWith('.mp4') ? url : undefined,
        }

        playerInstance.load(config).then(() => {
          setPlayer(playerInstance)
          if (playerInstance && onPlayerInit) onPlayerInit(playerInstance)
        })

        return playerInstance
      } catch (error) {
        Bugsnag.notify(`Failed to init video ${error}.`)
      }
    },
    [onPlayerInit, playerConfig],
  )

  useEffect(() => {
    let playerInstance: PlayerAPI | undefined
    setupPlayer(url).then((player) => {
      playerInstance = player
    })

    return () => {
      playerInstance?.destroy?.()
    }
  }, [setupPlayer, url])

  const UiComponent = ui

  return (
    <BitAngelPlayerProvider
      player={player}
      autoplay={autoplay}
      hideFullscreen={hideFullscreen}
      onStart={onStart}
      onPlaybackFinished={onPlaybackFinished}
    >
      <BitAngelPlayerTimeProvider player={player}>
        <div className={className} ref={containerRef}>
          <div>
            <div className={customStyles} ref={playerRef}>
              {player && <UiComponent />}
              {children}
            </div>
          </div>
        </div>
      </BitAngelPlayerTimeProvider>
    </BitAngelPlayerProvider>
  )
}
