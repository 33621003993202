import React, { useCallback, useEffect, useMemo, useReducer } from 'react'
import Bugsnag from '@bugsnag/js'
import { PlayerAPI, PlayerEventBase } from 'bitmovin-player'
import { PlayerEvent, ViewMode } from '@/organisms/AngelPlayer/types'
import {
  bitAngelPlayerReducer,
  BitAngelPlayerReducerState,
} from '@/organisms/BitAngelPlayer/BitAngelPlayerContext/BitAngelPlayerReducer'
import { ReactFCC } from '@/types/react'

interface BitAngelPlayerContextValues extends BitAngelPlayerReducerState {
  toggleFullscreen: () => void
  togglePlayback: () => void
  hideFullscreen: boolean
  hasCompleted: boolean
  isFullscreen: boolean
  isPlaying: boolean
  hasStarted: boolean
}

interface PlaybackEventWithSeek extends Partial<PlayerEventBase> {
  seekTo?: number
}

const noop = () => undefined

const defaultValues = {
  togglePlayback: noop,
  toggleFullscreen: noop,
  hasStarted: false,
  isPlaying: false,
  hasCompleted: false,
  isFullscreen: false,
  hideFullscreen: false,
}

const BitAngelPlayerContext = React.createContext<BitAngelPlayerContextValues>(defaultValues)

interface BitAngelPlayerProviderProps {
  onStart: () => void
  player?: PlayerAPI
  autoplay?: boolean
  hideFullscreen?: boolean
  onPlaybackFinished: () => void
}

export const BitAngelPlayerProvider: ReactFCC<BitAngelPlayerProviderProps> = ({
  children,
  player,
  autoplay,
  hideFullscreen = false,
  onStart,
  onPlaybackFinished,
}) => {
  const [state, dispatch] = useReducer(bitAngelPlayerReducer(player), {
    hasStarted: false,
    isPlaying: false,
    isFullscreen: false,
    hasCompleted: false,
  })

  const onPlay = useCallback(
    (event?: PlaybackEventWithSeek) => {
      if (!state.hasStarted) {
        onStart()
      }
      dispatch({
        type: 'play',
        payload: {
          seekTo: event?.seekTo,
        },
      })
    },
    [state.hasStarted, onStart, dispatch],
  )

  useEffect(() => {
    if (autoplay && player && !state.hasStarted) {
      onPlay()
    }
  }, [autoplay, player, state.hasStarted, onPlay])

  const toggleFullscreen = useCallback(() => {
    if (player?.getViewMode() === 'fullscreen') {
      player?.setViewMode(ViewMode.Inline)
    } else {
      player?.setViewMode(ViewMode.Fullscreen)
    }
  }, [player])

  const togglePlayback = useCallback(() => {
    if (state.isPlaying) {
      dispatch({ type: 'pause' })
    } else if (state.hasCompleted) {
      onPlay({ seekTo: 0 })
    } else {
      onPlay()
    }
  }, [state.isPlaying, onPlay, state.hasCompleted])

  useEffect(() => {
    if (player) {
      const onPause = () => {
        dispatch({
          type: 'pause',
        })
      }

      const onFinished = () => {
        if (!state.hasCompleted) {
          onPlaybackFinished()
        }
        dispatch({
          type: 'playbackFinished',
          payload: {
            hasCompleted: true,
          },
        })
      }

      try {
        player.on(PlayerEvent.Play, onPlay)
        player.on(PlayerEvent.Paused, onPause)
        player.on(PlayerEvent.PlaybackFinished, onFinished)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        Bugsnag.notify(`BitAngelPlayerContext - Error adding events from player: ${error?.message}`)
      }

      return () => {
        try {
          player.off(PlayerEvent.Play, onPlay)
          player.off(PlayerEvent.Play, onPause)
          player.off(PlayerEvent.PlaybackFinished, onFinished)
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
          Bugsnag.notify(`BitAngelPlayerContext - Error removing events from player: ${error?.message}`)
        }
      }
    }
  }, [player, onStart, onPlaybackFinished, state, onPlay])

  const value = useMemo(() => {
    return {
      toggleFullscreen,
      togglePlayback,
      hideFullscreen,
      ...state,
    }
  }, [toggleFullscreen, togglePlayback, hideFullscreen, state])

  return <BitAngelPlayerContext.Provider value={value}>{children}</BitAngelPlayerContext.Provider>
}

export const useBitAngelPlayer = () => {
  return React.useContext(BitAngelPlayerContext)
}
